<template>
    <div>
        <Table :loading='loading' :data='tableData' :height='500' :columns='columns'></Table>
    </div>
</template>

<script>
export default {
    props:{
        tableData:Object,
        houseCode:String,
        loading:Boolean,
    },
    data () {
        return {
            columns:[
                {
                title: "房号",
                width: 100,
                align: "center",
                render: (h, params) => {
                    return h("div", {},params.row.regionGovernmentVo.houseName)
                    },
                },
                {
                title: "姓名",
                key: "name",
                width: 100,
                align: "center"
                },
                {
                title: "性别",
                width: 80,
                align: "center",
                render: (h, params) => {
                    return h("div", {},params.row.sex == '0' ?'未知':params.row.sex == '1' ?'男':params.row.sex == '2'?'女':'')
                },
                },
                {
                title: "手机号码1",
                key: "phone",
                width: 130,
                align: "center"
                },
                {
                title: "与户主关系",
                width: 120,
                align: "center",
                render:(h,params)=>{
                    return h('div',{},
                    params.row.relationship?(this.relationshipList.map(item => {
                    if(item.dictKey == params.row.relationship){
                        return item.dictValue
                    }
                    })):'本人'
                    )}
                },
                {
                    title:'年龄',
                    key:'age',
                    width:80,
                    align:'center'
                },
                {
                    title:'类别',
                    key:'category',
                    width:250,
                    align:'center'
                },
                {
                title: "所在详细地址",
                minWidth: 300,
                align: "center",
                render: (h, params) => {
                    return h("div", [
                    h(
                        "span",
                        {
                        domProps: {
                            title: params.row.regionGovernmentVo.regionWholeAddr  || ''
                        },
                        on: {
                            click: e => {
                            e.stopPropagation();
                            }
                        }
                        },
                        params.row.regionGovernmentVo.regionWholeAddr  || ''
                    )
                    ]);
                }
                },
                {
                title: "是否家庭户代表",
                width: 140,
                align: "center",  
                key:'contact',
                render: (h, params) => {
                    return h("div", {},params.row.contact == '1' ?'是':params.row.contact == '2' ?'否':'')
                    }

                },
                {
                title:'是否已入驻网上家园',
                key:'settleOnlineHome',
                width: 180,
                align:'center',
                render: (h, params) => {
                    return h("div", {},params.row.settleOnlineHome == '1' ?'是':params.row.settleOnlineHome == '2' ?'否':'')
                    }
                },
                {
                title:'档案状态',
                width: 140,
                align:'center',
                render: (h, params) => {
                    return h("div", {},params.row.archiveStatus == '1' ?'正常':params.row.archiveStatus == '2' ?'已删除':params.row.archiveStatus == '3'?'已归档' : params.row.archiveStatus == '4' ?'已迁出':'')
                    }
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    width: 140,
                    fixed:"right",
                    render: (h, params) => {
                        return h("div", [
                        h(
                            "Dropdown",
                            {
                                props: {
                                    transfer: true
                                }
                            },
                            [
                            h(
                                "Button",
                                {
                                props: {
                                    type: "info",
                                    size: "small",
                                    icon: "ios-arrow-down"
                                }
                                },
                                "编辑"
                            ),
                            h(
                                "DropdownMenu",
                                {
                                    slot: "list"
                                },
                                [
                            
                        h(
                            "DropdownItem",
                            {
                            style: {
                                textAlign: "center"
                            },
                            nativeOn: {
                                click: () => {
                                this.alterDetails(params.row.userId,params.row.custGlobalId,params.row.familyId,params.row.familyMemberId,params.row.orgCode)
                                }
                            }
                            },
                            "修改"
                        ),
                        h(
                            "DropdownItem",
                            {
                            style: {
                                textAlign: "center !important",
                                display:params.row.archiveStatus == '4' || params.row.archiveStatus == '3'?'none':'block'
                            },
                            nativeOn: {
                                click: () => {
                                
                                this.$Modal.confirm({
                                title: "温馨提示",
                                content: "是否迁出改数据",
                                onOk: () => {
                                    this.$post('/syaa/api/syuser/pc/residentArchive/updateArchiveStatus',{
                                    status:4,
                                    userId:params.row.userId
                                },
                                {"Content-Type": "application/json"}).then(res => {
                                    if(res.code == 200){
                                    this.$Message.success({
                                        background: true,
                                        content: "迁出成功",
                                    });
                                    let data = {
                                        pageSize: this.pageSize,
                                        pageNum: this.pageNum
                                    };
                                    this.$emit('getList',this.houseCode)
                                    }
                                })
                                }})
                                }
                            }
                            },
                            "迁出"
                        ),
                        h(
                            "DropdownItem",
                            {
                            style: {
                                textAlign: "center",
                                display:params.row.archiveStatus == '3'?'none' :'block'
                            },
                            nativeOn: {
                                click: () => {
                                this.$Modal.confirm({
                                    title: "温馨提示",
                                    content: "是否归档该数据",
                                    onOk: () => {
                                    this.$post('/syaa/api/syuser/pc/residentArchive/updateArchiveStatus',{
                                        status:3,
                                        userId:params.row.userId
                                    },
                                    {"Content-Type": "application/json"}).then(res => {
                                        if(res.code == 200){
                                        this.$Message.success({
                                            background: true,
                                            content: "归档成功",
                                        });
                                        let data = {
                                            pageSize: this.pageSize,
                                            pageNum: this.pageNum
                                        };
                                        this.$emit('getList',this.houseCode)
                                        }
                                    })
                                }})
                                }
                            }
                            },
                            "归档"
                        ),
                        h(
                            "DropdownItem",
                            {
                            style: {
                                textAlign: "center"
                            },
                            nativeOn: {
                                click: () => {
                                this.remove(params.row);
                                }
                            }
                            },
                            "删除"
                        ),
                                ]
                                


                            )

                            ]
                        )
                        ]);
                    }
                    },
                
            ]
        }
    },
    methods:{
        //  修改详情
    alterDetails(userId,custGlobalId,familyId,familyMemberId,orgCode){
      this.$router.push({path:`/linehomeaddform?isEdit=true&userId=${userId}&custGlobalId=${custGlobalId}&familyId=${familyId}&familyMemberId=${familyMemberId}&orgCode=${orgCode}`,query:{isLineHome:this.$route.query.isLineHome ? this.$route.query.isLineHome :'',grid:'true'}});
      
    },
    remove(row) {
      if (row.familyMemberId) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认删除数据</p>",
          cancelText: "取消",
          okText: "确认",
          onOk: () => {
            this.$post("/syaa/pc/sy/user/online/home/delFamilyMember", {
              familyMemberId: row.familyMemberId
            })
              .then(res => {
                if (res.code === "200") {
                  this.$Message.success({content:"删除成功",background:true});
                  this.$emit('getList',this.houseCode)
                }else{
                   this.$Message.error({content:"删除失败",background:true});
                }
              })
          }
        });
      }
    },
    }
}
</script>

 
<style lang = "less" scoped>
    
</style>